.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.\!-left-px {
  left: -1px !important;
}

.\!left-\[487px\] {
  left: 487px !important;
}

.\!top-1 {
  top: .25rem !important;
}

.\!top-1\.5 {
  top: .375rem !important;
}

.\!top-2\.5 {
  top: .625rem !important;
}

.-left-px {
  left: -1px;
}

.-top-1 {
  top: -.25rem;
}

.-top-7 {
  top: -1.75rem;
}

.left-0 {
  left: 0;
}

.left-7 {
  left: 1.75rem;
}

.left-\[131px\] {
  left: 131px;
}

.left-\[135px\] {
  left: 135px;
}

.left-\[13px\] {
  left: 13px;
}

.left-\[17px\] {
  left: 17px;
}

.left-\[29px\] {
  left: 29px;
}

.left-\[34px\] {
  left: 34px;
}

.left-\[387px\] {
  left: 387px;
}

.left-\[43px\] {
  left: 43px;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[585px\] {
  left: 585px;
}

.left-\[600px\] {
  left: 600px;
}

.left-\[612px\] {
  left: 612px;
}

.left-\[657px\] {
  left: 657px;
}

.left-\[67px\] {
  left: 67px;
}

.left-\[698px\] {
  left: 698px;
}

.left-\[719px\] {
  left: 719px;
}

.left-\[720px\] {
  left: 720px;
}

.left-\[72px\] {
  left: 72px;
}

.left-\[74px\] {
  left: 74px;
}

.left-\[763px\] {
  left: 763px;
}

.left-\[778px\] {
  left: 778px;
}

.left-\[79px\] {
  left: 79px;
}

.left-\[88px\] {
  left: 88px;
}

.left-\[928px\] {
  left: 928px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-12 {
  top: 3rem;
}

.top-\[1024px\] {
  top: 1024px;
}

.top-\[117px\] {
  top: 117px;
}

.top-\[142px\] {
  top: 142px;
}

.top-\[184px\] {
  top: 184px;
}

.top-\[19px\] {
  top: 19px;
}

.top-\[220px\] {
  top: 220px;
}

.top-\[227px\] {
  top: 227px;
}

.top-\[2424px\] {
  top: 2424px;
}

.top-\[245px\] {
  top: 245px;
}

.top-\[303px\] {
  top: 303px;
}

.top-\[317px\] {
  top: 317px;
}

.top-\[321px\] {
  top: 321px;
}

.top-\[337px\] {
  top: 337px;
}

.top-\[346px\] {
  top: 346px;
}

.top-\[350px\] {
  top: 350px;
}

.top-\[3566px\] {
  top: 3566px;
}

.top-\[412px\] {
  top: 412px;
}

.top-\[468px\] {
  top: 468px;
}

.top-\[51px\] {
  top: 51px;
}

.top-\[576px\] {
  top: 576px;
}

.top-\[591px\] {
  top: 591px;
}

.top-\[594px\] {
  top: 594px;
}

.top-\[5px\] {
  top: 5px;
}

.top-\[607px\] {
  top: 607px;
}

.top-\[608px\] {
  top: 608px;
}

.top-\[60px\] {
  top: 60px;
}

.top-\[614px\] {
  top: 614px;
}

.top-\[672px\] {
  top: 672px;
}

.top-\[69px\] {
  top: 69px;
}

.top-\[74px\] {
  top: 74px;
}

.top-\[765px\] {
  top: 765px;
}

.top-\[849px\] {
  top: 849px;
}

.top-\[867px\] {
  top: 867px;
}

.top-\[870px\] {
  top: 870px;
}

.top-\[97px\] {
  top: 97px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-1\.50px\] {
  margin-top: -1.5px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.\!h-\[29\.13px\] {
  height: 29.13px !important;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-36 {
  height: 9rem;
}

.h-\[1024px\] {
  height: 1024px;
}

.h-\[1111px\] {
  height: 1111px;
}

.h-\[1142px\] {
  height: 1142px;
}

.h-\[1180px\] {
  height: 1180px;
}

.h-\[122px\] {
  height: 122px;
}

.h-\[127px\] {
  height: 127px;
}

.h-\[1400px\] {
  height: 1400px;
}

.h-\[186px\] {
  height: 186px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[277px\] {
  height: 277px;
}

.h-\[29px\] {
  height: 29px;
}

.h-\[3629px\] {
  height: 3629px;
}

.h-\[37px\] {
  height: 37px;
}

.h-\[47px\] {
  height: 47px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[53px\] {
  height: 53px;
}

.h-\[551px\] {
  height: 551px;
}

.h-\[618px\] {
  height: 618px;
}

.h-\[63px\] {
  height: 63px;
}

.h-\[754px\] {
  height: 754px;
}

.h-\[75px\] {
  height: 75px;
}

.h-\[78px\] {
  height: 78px;
}

.h-\[79px\] {
  height: 79px;
}

.h-\[82px\] {
  height: 82px;
}

.h-\[86px\] {
  height: 86px;
}

.h-\[896px\] {
  height: 896px;
}

.h-\[904px\] {
  height: 904px;
}

.\!w-\[135\.48px\] {
  width: 135.48px !important;
}

.w-0\.5 {
  width: .125rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-14 {
  width: 3.5rem;
}

.w-28 {
  width: 7rem;
}

.w-\[108px\] {
  width: 108px;
}

.w-\[121px\] {
  width: 121px;
}

.w-\[125px\] {
  width: 125px;
}

.w-\[1305px\] {
  width: 1305px;
}

.w-\[135px\] {
  width: 135px;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[37px\] {
  width: 37px;
}

.w-\[454px\] {
  width: 454px;
}

.w-\[458px\] {
  width: 458px;
}

.w-\[46px\] {
  width: 46px;
}

.w-\[47px\] {
  width: 47px;
}

.w-\[497px\] {
  width: 497px;
}

.w-\[501px\] {
  width: 501px;
}

.w-\[526px\] {
  width: 526px;
}

.w-\[528px\] {
  width: 528px;
}

.w-\[537px\] {
  width: 537px;
}

.w-\[53px\] {
  width: 53px;
}

.w-\[547px\] {
  width: 547px;
}

.w-\[562px\] {
  width: 562px;
}

.w-\[570px\] {
  width: 570px;
}

.w-\[585px\] {
  width: 585px;
}

.w-\[58px\] {
  width: 58px;
}

.w-\[607px\] {
  width: 607px;
}

.w-\[615px\] {
  width: 615px;
}

.w-\[642px\] {
  width: 642px;
}

.w-\[67px\] {
  width: 67px;
}

.w-\[68px\] {
  width: 68px;
}

.w-\[69px\] {
  width: 69px;
}

.w-\[701px\] {
  width: 701px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[720px\] {
  width: 720px;
}

.w-\[783px\] {
  width: 783px;
}

.w-\[78px\] {
  width: 78px;
}

.w-\[82px\] {
  width: 82px;
}

.w-\[836px\] {
  width: 836px;
}

.w-\[840px\] {
  width: 840px;
}

.w-\[881px\] {
  width: 881px;
}

.w-\[918px\] {
  width: 918px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-16 {
  gap: 4rem;
}

.gap-\[500px\] {
  gap: 500px;
}

.gap-\[58px\] {
  gap: 58px;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[22px\] {
  border-radius: 22px;
}

.rounded-\[23\.5px\] {
  border-radius: 23.5px;
}

.rounded-\[7px\] {
  border-radius: 7px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-solid {
  border-style: solid;
}

.border-\[\#01a6dc\] {
  --tw-border-opacity: 1;
  border-color: rgb(1 166 220 / var(--tw-border-opacity, 1));
}

.bg-\[\#007ba3\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 163 / var(--tw-bg-opacity, 1));
}

.bg-\[\#0090c0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 144 192 / var(--tw-bg-opacity, 1));
}

.bg-\[\#01a6dc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 166 220 / var(--tw-bg-opacity, 1));
}

.bg-\[\#0dc3ff1c\] {
  background-color: #0dc3ff1c;
}

.bg-\[\#26f83b1c\] {
  background-color: #26f83b1c;
}

.bg-\[\#26f83b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(38 248 59 / var(--tw-bg-opacity, 1));
}

.bg-\[\#26f8b9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(38 248 185 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f8a426\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 164 38 / var(--tw-bg-opacity, 1));
}

.bg-\[\#ffc56e1c\] {
  background-color: #ffc56e1c;
}

.bg-\[\#ffffff80\] {
  background-color: #ffffff80;
}

.bg-\[\#ffffff99\] {
  background-color: #fff9;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.px-\[139px\] {
  padding-left: 139px;
  padding-right: 139px;
}

.px-\[29px\] {
  padding-left: 29px;
  padding-right: 29px;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-\[52px\] {
  font-size: 52px;
}

.text-\[57px\] {
  font-size: 57px;
}

.text-\[68px\] {
  font-size: 68px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-\[102px\] {
  line-height: 102px;
}

.leading-\[22\.6px\] {
  line-height: 22.6px;
}

.leading-\[31\.3px\] {
  line-height: 31.3px;
}

.leading-\[34\.8px\] {
  line-height: 34.8px;
}

.leading-\[41\.8px\] {
  line-height: 41.8px;
}

.leading-\[52\.2px\] {
  line-height: 52.2px;
}

.leading-\[68\.4px\] {
  line-height: 68.4px;
}

.leading-\[83\.5px\] {
  line-height: 83.5px;
}

.leading-\[normal\] {
  line-height: normal;
}

.tracking-\[0\.12px\] {
  letter-spacing: .12px;
}

.tracking-\[0\.26px\] {
  letter-spacing: .26px;
}

.tracking-\[0\.36px\] {
  letter-spacing: .36px;
}

.tracking-\[0\.40px\] {
  letter-spacing: .4px;
}

.tracking-\[0\.48px\] {
  letter-spacing: .48px;
}

.tracking-\[0\.60px\] {
  letter-spacing: .6px;
}

.tracking-\[0\.80px\] {
  letter-spacing: .8px;
}

.tracking-\[0\.90px\] {
  letter-spacing: .9px;
}

.tracking-\[0\.96px\] {
  letter-spacing: .96px;
}

.tracking-\[1\.14px\] {
  letter-spacing: 1.14px;
}

.tracking-\[1\.36px\] {
  letter-spacing: 1.36px;
}

.tracking-\[1\.40px\] {
  letter-spacing: 1.4px;
}

.tracking-\[1\.70px\] {
  letter-spacing: 1.7px;
}

.tracking-\[2\.60px\] {
  letter-spacing: 2.6px;
}

.text-\[\#0000001a\] {
  color: #0000001a;
}

.text-\[\#01a6dc\] {
  --tw-text-opacity: 1;
  color: rgb(1 166 220 / var(--tw-text-opacity, 1));
}

.text-\[\#0dc3ff\] {
  --tw-text-opacity: 1;
  color: rgb(13 195 255 / var(--tw-text-opacity, 1));
}

.text-\[\#26f83b\] {
  --tw-text-opacity: 1;
  color: rgb(38 248 59 / var(--tw-text-opacity, 1));
}

.text-\[\#343434\] {
  --tw-text-opacity: 1;
  color: rgb(52 52 52 / var(--tw-text-opacity, 1));
}

.text-\[\#595959\] {
  --tw-text-opacity: 1;
  color: rgb(89 89 89 / var(--tw-text-opacity, 1));
}

.text-\[\#f8a426\] {
  --tw-text-opacity: 1;
  color: rgb(248 164 38 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.shadow-shadow-small {
  --tw-shadow: var(--shadow-small);
  --tw-shadow-colored: var(--shadow-small);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur-\[20px\] {
  --tw-blur: blur(20px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-\[26px\] {
  --tw-blur: blur(26px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-\[45px\] {
  --tw-backdrop-blur: blur(45px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-brightness-\[100\%\] {
  --tw-backdrop-brightness: brightness(100%);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.\[-webkit-backdrop-filter\:blur\(45px\)_brightness\(100\%\)\] {
  -webkit-backdrop-filter: blur(45px) brightness();
}

.\[-webkit-text-stroke\:1px_\#000000\] {
  -webkit-text-stroke: 1px #000;
}

.\[font-family\:\'Poppins\'\,Helvetica\] {
  font-family: Poppins, Helvetica;
}

.\[font-family\:\'Radio_Canada\'\,Helvetica\] {
  font-family: Radio Canada, Helvetica;
}

:root {
  --shadow-small: 0px .764683px 5.81622px 0px #00000005, 0px 1.77795px 13.5232px 0px #00000008, 0px 3.19278px 24.2845px 0px #0000000a, 0px 5.29883px 40.3033px 0px #0000000d, 0px 8.72965px 66.3983px 0px #0000000f, 0px 15.2569px 116.045px 0px #00000012, 0px 33px 251px 0px #00000017;
}
/*# sourceMappingURL=index.f6d32a47.css.map */
