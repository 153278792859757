@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --shadow-small: 0px 0.7646827697753906px 5.816223621368408px 0px rgba(0, 0, 0, 0.02),
    0px 1.7779473066329956px 13.523175239562988px 0px rgba(0, 0, 0, 0.03),
    0px 3.192781448364258px 24.284488677978516px 0px rgba(0, 0, 0, 0.04),
    0px 5.298834323883057px 40.30325698852539px 0px rgba(0, 0, 0, 0.05),
    0px 8.72965145111084px 66.39825439453125px 0px rgba(0, 0, 0, 0.06),
    0px 15.2568998336792px 116.04490661621094px 0px rgba(0, 0, 0, 0.07), 0px 33px 251px 0px rgba(0, 0, 0, 0.09);
}
